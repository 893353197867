import SliderDg from '../SliderDg'
import { FormattedMessage } from 'react-intl'

import PropTypes from 'prop-types'

const filters = [
  {
    value: 'all',
    title: <FormattedMessage id='all-courses' defaultMessage='All Courses' />,
  },
  {
    value: 'beginner',
    title: <FormattedMessage id='beginner' defaultMessage='Beginner' />,
  },
  {
    value: 'advanced',
    title: <FormattedMessage id='advanced' defaultMessage='Advanced' />,
  },
]

const PopularCourses = ({
  courses,
  activeFilter = 'all',
  handleClick,
  forwardedRef,
}) => {
  return (
    <div className='slider-dg-wrapper' ref={forwardedRef}>
      <div className='slider-dg-buttongroup'>
        {filters.map((filter) => {
          const onClick = () => handleClick(filter.value)
          return (
            <span
              key={filter.value}
              onClick={onClick}
              className={`slider-dg-button${
                activeFilter === filter.value ? ' active' : ''
              }`}
            >
              {filter.title}
            </span>
          )
        })}
      </div>
      {courses && courses.length ? (
        <SliderDg data={courses} view={3} name='popular-courses' />
      ) : (
        <p className='no-info-slider'>
          <FormattedMessage id='no_courses' defaultMessage='No courses' />
        </p>
      )}
    </div>
  )
}

PopularCourses.propTypes = {
  courses: PropTypes.array,
  activeFilter: PropTypes.string,
  handleClick: PropTypes.func,
  forwardedRef: PropTypes.object,
}

export default PopularCourses
