import { useEffect, useState } from 'react'
import Api from '../../../../api'
import { createPadiCourseUrl } from '../../../../localized-urls'
import { PriceConverter } from '../../../helpers'
import { pushToDataLayer } from '../../../../gtm'
import { useSelector } from 'react-redux'

export default function usePopularCourses(
  objectId,
  isInitialMount,
  activeFilter,
  inViewport,
) {
  const [courses, setCourses] = useState([])
  const [isLoading, setLoading] = useState(true)
  const { selectedCurrency } = useSelector(({ selectedCurrency }) => ({
    selectedCurrency,
  }))

  useEffect(() => {
    if(!inViewport) {
      return
    }
    setLoading(true)
    const impressions = []
    Api(`dive-guide/${objectId}/products/`)
      .get()
      .then((response) => {
        const normalized = response.map((item, index) => {
          const impression = {
            name: item?.padiName,
            id: item?.padiId,
            price: item?.price?.targetValue,
            brand: 'PADI',
            category: `elearning course${!item?.price ? ' (unavailable)' : ''}`,
            list: window.currentPageType,
            position: index + 1,
          }
          impressions.push(impression)
          return {
            ...item,
            price: item?.price ? (
              <PriceConverter
                amount={item?.price?.targetValue}
                currency={item?.price?.targetCurrency}
              />
            ) : (
              ''
            ),
            impression,
            img: item.thumbnail['400x400'],
            url: createPadiCourseUrl(item.slug),
          }
        })
        pushToDataLayer({
          event: 'impression',
          ecommerce: {
            currencyCode: selectedCurrency,
            impressions,
          },
        })
        setCourses(normalized)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [objectId, inViewport])

  useEffect(() => {
    if(!isInitialMount.current) {
      if(activeFilter === 'beginner') {
        setCourses(courses.filter((item) => item?.isBeginner))
      } else if(activeFilter === 'advanced') {
        setCourses(courses.filter((item) => !item.isBeginner))
      } else {
        setCourses(courses)
      }
    }
  }, [activeFilter])

  return {
    courses,
    isLoading,
    setCourses,
  }
}
