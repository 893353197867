import { useRef, useState } from 'react'
import PopularCourses from './PopularCourses'
import Loader from '../../helpers/Loader'
import usePopularCourses from './hooks/usePopularCourses'
import { handleViewport } from 'react-in-viewport'
import PropTypes from 'prop-types'

const Courses = ({ objectId, inViewport, forwardedRef }) => {
  const [activeFilter, setActiveFilter] = useState()
  const isInitialMount = useRef(true)

  const { courses, isLoading } = usePopularCourses(
    objectId,
    isInitialMount,
    activeFilter,
    inViewport,
  )

  const onFilterClick = (filter) => setActiveFilter(filter)

  if(isLoading) {
    return (
      <div className='react-app-loading mth' ref={forwardedRef}>
        <Loader />
      </div>
    )
  }

  return (
    <PopularCourses
      ref={forwardedRef}
      courses={courses}
      handleClick={onFilterClick}
      activeFilter={activeFilter}
    />
  )
}

Courses.propTypes = {
  objectId: PropTypes.string.isRequired,
  inViewport: PropTypes.bool.isRequired,
  forwardedRef: PropTypes.object,
}

export default handleViewport(Courses, {}, { disconnectOnLeave: true })
